export class StatusLog {
  constructor(
    public Id: number | null,
    public Type: string | null,
    public SourceAppId: string | null,
    public SourceSubId: string | null,
    public ApplicationType: string | null,
    public Status: string | null,
    public Path: string | null,
    public ProcessedDate: string | null,
    public ErrorMessage: string | null,
    public ErrorStack: string | null,
    public UserId: string | null,
  ) {}

  static FromObject(obj: any): StatusLog {
    return new StatusLog(
      obj.Id,
      obj.Type,
      obj.SourceAppId,
      obj.SourceSubId,
      obj.ApplicationType,
      obj.Status,
      obj.Path,
      obj.Processed,
      obj.ErrorMessage,
      obj.ErrorStack,
      obj.UserId,
    );
  }

  get FormattedProcessedDate(): string {
    if (!this.ProcessedDate) {
      return '';
    }
    const dateStr = this.ProcessedDate.match(/[0-9]+/);
    if (!dateStr) return '';

    const date = new Date(parseInt(dateStr[0]));
    const formatter = new Intl.DateTimeFormat('en-us', {
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    return formatter.format(date);
  }
}
