
import Vue from 'vue';

import { mapMutations, mapState } from 'vuex';

import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';
import { StatusLog } from '@/models/statuslog';

export default Vue.extend({
  data(): {
    showDialog: boolean;
    logDetail: StatusLog;
  } {
    return {
      showDialog: false,
      logDetail: StatusLog.FromObject({}),
    };
  },
  computed: {
    ...mapState(['statusDialogState', 'statusDialogInfo', 'activeCompany']),
  },
  watch: {
    showDialog(): void {
      this.changeStatusDialogState(this.showDialog);
    },
    statusDialogState(state: boolean): void {
      this.showDialog = state;
    },
    async statusDialogInfo(logId: number): Promise<void> {
      if (!this.activeCompany) {
        return;
      }
      const log = (await GlobalHelper.http(
        `./api/Status/GetLogDetails?companyId=${this.activeCompany.Id}&logId=${logId}`,
        ErrorHelper.StatusGetError,
      )) as StatusLog;
      this.logDetail = StatusLog.FromObject(log);
    },
  },
  methods: {
    ...mapMutations(['changeStatusDialogState', 'changeStatusDialogInfo']),
    copyContent(): void {
      const success = GlobalHelper.addToClipboard(
        `Application Number\n${this.logDetail.SourceAppId}\n\n` +
          `Sequence\n${this.logDetail.SourceSubId}\n\n` +
          `Path\n${this.logDetail.Path}\n\n` +
          `Processed Date\n${this.logDetail.FormattedProcessedDate}\n\n` +
          `Error Message\n${this.logDetail.ErrorMessage}\n\n` +
          `Error Stack\n${this.logDetail.ErrorStack}`,
      );

      if (success) {
        ErrorHelper.addSnackbarMessage('Copied to clipboard', 'success');
      } else {
        ErrorHelper.addSnackbarMessage('Copy failed. Please try again', 'error');
      }
    },
  },
});
