
import Vue from 'vue';

import { mapActions, mapMutations, mapState } from 'vuex';

import CompanySelector from 'common-vue-components/components/Dropdowns/CompanySelector.vue';
import NineDot from 'common-vue-components/components/Dropdowns/NineDot.vue';

import Info from '@/components/Dropdowns/Info.vue';

export default Vue.extend({
  components: {
    NineDot,
    Info,
    CompanySelector,
  },
  data(): {
    nineDot: boolean;
    info: boolean;
  } {
    return {
      nineDot: false,
      info: false,
    };
  },
  computed: {
    ...mapState([
      'hasAccess',
      'companies',
      'activeCompany',
      'areCompaniesLoading',
      'canAccessConfiguration',
    ]),
  },
  methods: {
    ...mapActions(['changeActiveCompany']),
    ...mapMutations(['changeDrawerState', 'changeConfigurationDialogState']),
    openConfiguration(): void {
      this.changeConfigurationDialogState();
    },
    signOut() {
      this.$auth.signOut();
    },
    showHideDrawer(): void {
      this.changeDrawerState();
    },
  },
});
