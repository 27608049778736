export enum ProcessOptions {
  XMLStructure = 'XML/Structure',
  ProcessPdfs = 'Process PDF',
  VerifyChecksums = 'Verify Checksums',
}

export enum ResponseTypes {
  Success = 'success',
  Error = 'error',
}
