export class ConfigurationModel {
  constructor(
    public ReviewFolder: string | null,
    public PublishFolder: string | null,
    public AllowReload: boolean = false,
    public ValidatePdfs: boolean = false,
    public ValidateChecksums: boolean = false,
    public ValidateXMLStructure: boolean = true,
  ) {}

  public static FromObject(obj: any): ConfigurationModel {
    return new ConfigurationModel(
      obj.ReviewFolder,
      obj.PublishFolder,
      obj.AllowReload,
      obj.ValidatePdfs,
      obj.ValidateChecksums,
      obj.ValidateXMLStructure,
    );
  }
}
