
import Vue from 'vue';

import Cookies from 'js-cookie';
import { mapGetters, mapState } from 'vuex';

import DownloadObject from '@/components/Storage/Download/types/downloadObject';
import { ResponseTypes } from '@/helpers/enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/eventBus';
import GlobalHelper from '@/helpers/globalHelper';

export default Vue.extend({
  computed: {
    ...mapGetters(['currentLocationPath', 'downloadAvailable']),
    ...mapState(['selectedFolders', 'activeCompany']),
  },
  mounted() {
    EventBus.$on('download', () => {
      this.download();
    });
  },
  methods: {
    async download() {
      if (!this.activeCompany) {
        return;
      }

      const objects: DownloadObject[] = this.selectedFolders.map((item: any) => ({
        IsFolder: item.IsFolder,
        Path: item.Name,
      }));

      const expiresDate = new Date();
      expiresDate.setTime(expiresDate.getTime() + 30 * 1000);
      Cookies.set('authorization', btoa(GlobalHelper.getAuthorization()), {
        sameSite: 'Strict',
        expires: expiresDate,
      });

      const urlParams = new URLSearchParams();

      urlParams.set('companyId', this.activeCompany.Id);
      urlParams.set('currentFolderPath', this.currentLocationPath);
      urlParams.set('objects', JSON.stringify(objects));

      const link = document.createElement('a');
      link.setAttribute('download', name);
      link.href = `api/StorageDownload/Download?${urlParams.toString()}`;
      link.style.display = 'none';

      document.body.appendChild(link);

      link.click();
      link.remove();

      ErrorHelper.addSnackbarMessage('Download will start momentarily', ResponseTypes.Success);
    },
  },
});
