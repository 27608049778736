
import Vue from 'vue';

import { mapMutations, mapState } from 'vuex';

import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';

export default Vue.extend({
  computed: {
    ...mapState(['contactDialogState', 'infoDialogState']),
  },
  methods: {
    ...mapMutations(['changeContactDialogState', 'changeInfoDialogState']),
    openModal(name: string): void {
      if (name === 'help') {
        this.changeContactDialogState();
      } else {
        this.changeInfoDialogState();
      }
    },
    async openUserGuide(): Promise<void> {
      const url = await GlobalHelper.http(
        'api/Main/GetUserGuideUrl',
        ErrorHelper.UserGuideGetError,
      );
      if (url) {
        window.open(url, '_blank');
      }
    },
  },
});
