
import Vue from 'vue';

import $ from 'jquery';
import { InputValidationRules } from 'vuetify';
import { mapGetters, mapMutations, mapState } from 'vuex';

import FileRenameResponse from '@/components/Storage/Rename/types/renameFileResponse';
import { ResponseTypes } from '@/helpers/enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/eventBus';
import GlobalHelper from '@/helpers/globalHelper';

export default Vue.extend({
  data(): {
    dialog: boolean;
    name: string;
    nameError: string;
    submitting: boolean;
  } {
    return {
      dialog: false,
      name: '',
      nameError: '',
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(['currentLocationPath']),
    ...mapState(['selectedFolders', 'activeCompany']),
    selectedFile(): any {
      return this.selectedFolders.length === 1 && !this.selectedFolders[0]?.IsFolder
        ? this.selectedFolders[0]
        : null;
    },
    nameRules(): InputValidationRules {
      return this.nameError ? [this.nameError] : [];
    },
  },
  watch: {
    name: function () {
      this.nameError = '';
    },
  },
  mounted() {
    EventBus.$on('rename', () => {
      this.openDialog();
    });
  },
  methods: {
    ...mapMutations(['refresh']),
    openDialog() {
      this.dialog = true;
      this.name = this.selectedFile.Name;
    },
    rename() {
      if (!this.activeCompany) {
        return;
      }

      this.submitting = true;
      this.nameError = '';

      $.ajax({
        url: 'api/Storage/RenameFile',
        method: 'POST',
        headers: GlobalHelper.getHeaders(),
        data: {
          companyId: this.activeCompany.Id,
          path: this.currentLocationPath,
          oldFileName: this.selectedFile.Name,
          newFileName: this.name,
        },
      })
        .done((response: FileRenameResponse) => {
          if (!response.IsSuccess) {
            this.nameError = response.ErrorMessage;
          } else {
            ErrorHelper.addSnackbarMessage('File is renamed successfully', ResponseTypes.Success);
          }
        })
        .fail((error: any) => {
          ErrorHelper.addSnackbarMessage(
            'The file renaming failed',
            ResponseTypes.Error,
            error.responseText,
            true,
          );
          this.refresh();
        })
        .always(() => {
          this.submitting = false;
          if (!this.nameError) {
            this.dialog = false;
            this.refresh();
          }
        });
    },
    closeDialog() {
      this.dialog = false;
    },
  },
});
