import { Events } from '@/constants';
import { EventBus } from '@/helpers/eventBus';
import { AlertMessage } from '@/models/alertMessage';

export default class ErrorHelper {
  public static addSnackbarMessage(
    message: string,
    color: string,
    errorMessage = '',
    showContactSupport = false,
    logId = 0,
    timeout: number | null = null,
  ): void {
    let timeoutValue = timeout ?? (color === 'error' ? 0 : 5000);
    if (timeoutValue === 0) {
      timeoutValue = -1;
    }

    const alert = new AlertMessage(message, color, timeoutValue, errorMessage, logId);
    EventBus.$emit(Events.addSnackbar, alert, showContactSupport);
  }

  public static EnqueueProcessError =
    'Unable to enqueue the processing of this item. Please try again.';
  public static EnqueueRemoveError =
    'Unable to enqueue the removal of this item. Please try again.';
  public static ConfigurationSaveError = 'Unable to save configuration. Please try again.';
  public static ConfigurationGetError = 'Could not get configuration.';
  public static CompaniesGetError = 'Could not get companies.';
  public static GridDataGetError = 'Unable to get grid data.';
  public static StatusGetError = 'Unable to get status.';
  public static QueueError = 'Unable to get queue data';
  public static PermissionError = 'Unable to get user permissions.';
  public static UserGuideGetError = 'Could not get user doc.';
}
