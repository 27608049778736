
import Vue from 'vue';

import { mapMutations, mapState } from 'vuex';

export default Vue.extend({
  data(): {
    currentYear: number;
    imageUrl: string;
    infoDialog: boolean;
    version: string;
  } {
    return {
      currentYear: new Date().getFullYear(),
      imageUrl: '',
      infoDialog: false,
      version: '',
    };
  },
  computed: {
    ...mapState(['infoDialogState']),
  },
  watch: {
    infoDialogState(): void {
      this.infoDialog = this.infoDialogState;
    },
    infoDialog(): void {
      if (this.infoDialog === false) {
        this.changeInfoDialogState();
      }
    },
  },
  mounted(): void {
    this.version = window.version;
    this.imageUrl = window.baseUrl + './Content/images/certaralogo.svg';
  },
  methods: {
    ...mapMutations(['changeInfoDialogState']),
  },
});
