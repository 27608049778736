
import Vue from 'vue';

import $ from 'jquery';
import { mapGetters, mapMutations, mapState } from 'vuex';

import { DeleteStatuses } from '@/components/Storage/Delete/enums/deleteStatuses';
import DeleteObj from '@/components/Storage/Delete/types/deleteObj';
import DeleteObjResponse from '@/components/Storage/Delete/types/deleteObjResponse';
import { ResponseTypes } from '@/helpers/enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/eventBus';
import GlobalHelper from '@/helpers/globalHelper';

export default Vue.extend({
  data(): {
    dialog: boolean;
    status: DeleteStatuses | undefined;
    DeleteStatuses: any;
  } {
    return {
      dialog: false,
      status: undefined,
      DeleteStatuses,
    };
  },
  computed: {
    ...mapGetters(['currentLocationPath', 'deleteAvailable']),
    ...mapState(['selectedFolders', 'activeCompany']),
    itemsNames(): string {
      return (this.selectedFolders || [])
        .filter((item: any) => item)
        .map((item: any) => item.Name)
        .join(', ');
    },
  },
  mounted() {
    EventBus.$on('delete', () => {
      this.openDialog();
    });
  },
  methods: {
    ...mapMutations(['refresh']),
    openDialog() {
      this.dialog = true;
    },
    deleteFiles() {
      if (!this.activeCompany) {
        return;
      }

      this.status = DeleteStatuses.Deleting;

      const objects: DeleteObj[] = this.selectedFolders.map((item: any) => ({
        IsFolder: item.IsFolder,
        Path: item.Name,
      }));

      $.ajax({
        url: 'api/Storage/Delete',
        method: 'POST',
        headers: GlobalHelper.getHeaders(),
        data: {
          companyId: this.activeCompany.Id,
          objects,
          currentLocationPath: this.currentLocationPath,
        },
      })
        .done((result: DeleteObjResponse[]) => {
          const failed = result.filter((x: DeleteObjResponse) => !x.IsSuccess);
          if (failed.length) {
            ErrorHelper.addSnackbarMessage(
              `The deleting for ${failed
                .map((x: DeleteObjResponse) => x.Path)
                .join(', ')} file(s) failed`,
              ResponseTypes.Error,
              `${failed
                .map((x: DeleteObjResponse) => `${x.Path} - ${x.ErrorMessage}`)
                .join('%0D%0A')}`,
              true,
            );
          } else {
            ErrorHelper.addSnackbarMessage(
              'The files are deleted successfully',
              ResponseTypes.Success,
            );
          }
        })
        .fail((error: any) => {
          ErrorHelper.addSnackbarMessage(
            'The deleting file(s) failed',
            ResponseTypes.Error,
            error.responseText,
            true,
          );
        })
        .always(() => {
          this.status = undefined;
          this.dialog = false;
          this.refresh();
        });
    },
    cancelDeleting() {
      this.status = undefined;
      this.dialog = false;
    },
  },
});
