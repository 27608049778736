
import Vue from 'vue';

import $ from 'jquery';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import IdleManager from 'common-vue-components/helpers/IdleManager';

import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/eventBus';
import GlobalHelper from '@/helpers/globalHelper';
import LoadDataGridBuilder from '@/helpers/loadDataGridBuilder';
import { QueueItem } from '@/models/queueItem';

export default Vue.extend({
  props: {
    search: {
      type: String,
    },
  },
  data(): {
    selected: any[];
    headers: any[];
    items: any[];
    dataLoading: boolean;
    currentPage: number;
    folderImage: string;
    polling: any;
    currentOperations: any;
    GlobalHelper: any;
    itemsPerPage: number;
  } {
    return {
      selected: [],
      headers: [
        { text: '', align: 'center', sortable: false, value: 'Menu', width: '28px' },
        { text: 'Name', align: 'start', sortable: true, value: 'Name' },
        { text: 'Status', sortable: false, value: 'Status' },
        { text: 'Size', sortable: false, value: 'Size' },
        { text: 'Last Modified', sortable: false, value: 'LastModifiedString' },
      ],
      items: [],
      currentPage: 1,
      dataLoading: true,
      folderImage: '',
      polling: null,
      currentOperations: null,
      GlobalHelper,
      itemsPerPage: 20,
    };
  },
  computed: {
    DataGridBuilder()
    {
      return LoadDataGridBuilder
    },
    ...mapState([
      'currentLocation',
      'queueData',
      'failedOperations',
      'configurationInfo',
      'repoGridAutoRefresh',
      'highlightedItem',
      'activeCompany',
    ]),
    ...mapGetters([
      'currentLocationPath',
      'renameAvailable',
      'downloadAvailable',
      'deleteAvailable',
    ]),
  },
  watch: {
    async currentLocation(): Promise<void> {
      this.items = [];
      await this.getCurrentRepoData();
      this.currentPage = 1;
      this.highlightedItem && this.scrollToHighlightedItem();
      this.setStatus();
    },
    queueData(): void {
      if (this.currentOperations && this.currentOperations.length !== 0) {
        const currentArr = this.makeQueueArray(this.currentOperations);
        const incomingArr = this.makeQueueArray(this.queueData);
        const completedOperations = GlobalHelper.queueDiffs(incomingArr, currentArr);
        if (completedOperations.length !== 0) {
          this.updateCompletedOperations(completedOperations);
        }
      }
      this.currentOperations = this.queueData;
      this.setStatus();
    },
    selected(): void {
      this.changeSelectedFolders(this.selected);
    },
    configurationInfo(): void {
      this.updateGrid();
    },
    repoGridAutoRefresh(): void {
      this.setPolling(this.repoGridAutoRefresh);
    },
  },
  async mounted(): Promise<void> {
    this.updateGrid();
    this.setStatus();
    this.setPolling(true);
    this.changeSelectedFolders(this.selected);

    IdleManager.onIdle((idle) => {
      this.changeRepoGridAutoRefresh(!idle);
    });
  },
  methods: {
    ...mapActions(['getQueueData']),
    ...mapMutations([
      'addNewLocation',
      'changeFailedOperations',
      'changeSelectedFolders',
      'setHighlightedItem',
      'changeRepoGridAutoRefresh',
    ]),
    getHumanReadableSize(size: string): string {
      const divisor = 1000;
      const units: string[] = ['bytes', 'kB', 'MB', 'GB', 'TB'];
      let u = 0;
      let bytes: number = parseFloat(size);
      while (Math.abs(bytes) >= divisor && u < units.length - 1) {
        bytes /= divisor;
        u += 1;
      }
      return bytes.toFixed(u === 0 ? 0 : 2) + ' ' + units[u];
    },
    getHumanReadableDate(date: string): string {
      const localDate = new Date(Date.parse(date)).toLocaleString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
      return localDate;
    },
    setStatus(): void {
      if (this.currentOperations == null || this.currentOperations.length === 0) return;

      const end = this.items.length;
      const parentPath = GlobalHelper.ConstructPath();
      for (let i = 0; i < end; i++) {
        if (!this.items[i].IsFolder) continue;
        const path = `${parentPath}/${this.items[i].Name}`;
        const match = this.currentOperations.find(
            GlobalHelper.queueItemPathComparer(path, parentPath),
        );

        if (match !== undefined) {
          const newItem = this.items[i];
          const itemIndex = this.items.indexOf(newItem);
          newItem.Status = match.QueueStatus;
          this.items.splice(itemIndex, 1, newItem);
        }
      }
    },
    async updateCompletedOperations(completedOperations: QueueItem[]): Promise<void> {
      if (!this.activeCompany) {
        return;
      }

      $.ajax({
        url: 'api/Main/UpdateCompletedOperations',
        headers: GlobalHelper.getHeaders(),
        data: JSON.stringify({
          companyId: this.activeCompany.Id,
          items: completedOperations,
        }),
        contentType: 'application/json, charset=utf-8',
        dataType: 'json',
        type: 'post',
      }).done((result: any) => {
        this.notifyUserOfCompletion(result);
      });
    },
    notifyUserOfCompletion(result: any[]): void {
      result.forEach((log) => {
        if (!log.Succeeded) {
          if (!this.failedOperations) {
            this.changeFailedOperations();
          }
        }
      });
      this.getCurrentRepoData();
      this.setStatus();
    },
    makeQueueArray(operations: any[]): QueueItem[] {
      const results: QueueItem[] = [];
      operations.forEach((entry) => {
        results.push(
            new QueueItem(
                entry.Id,
                entry.UserId,
                entry.Type,
                entry.QueueType,
                entry.Path,
                entry.QueueId,
                entry.QueueStatus,
                entry.IsUser,
                entry.LogId,
                entry.Succeeded,
            ),
        );
      });

      return results;
    },
    async getCurrentRepoData(): Promise<void> {
      if (!this.activeCompany) {
        return;
      }

      const activeCompanyId = this.activeCompany.Id;
      const currentLocation = this.currentLocation[this.currentLocation.length - 1];
      const newPath: any = encodeURIComponent(GlobalHelper.ConstructPath());
      const parentIsApp: boolean =
          currentLocation.currentObject != null && currentLocation.currentObject.IsLoadedApp != null
              ? currentLocation.currentObject.IsLoadedApp
              : false;
      this.dataLoading = true;
      const result = (await this.getGridData(activeCompanyId, newPath, parentIsApp)) as any;
      this.dataLoading = false;
      if (!result.IsSuccess) {
        ErrorHelper.addSnackbarMessage(
            result.ErrorMessage,
            'error',
            result.ErrorMessage,
            true,
            0,
            5000,
        );
        return;
      }

      if (currentLocation === this.currentLocation[this.currentLocation.length - 1]) {
        this.items = result.Items;
      }

      this.selected = (this.items || []).filter((item) => {
        return item.Name === this.selected[0]?.Name;
      });
    },
    async getGridData(
        activeCompanyId: number,
        newPath: string | null,
        parentIsApp: boolean | null,
    ): Promise<any[]> {
      if (newPath != null) {
        return GlobalHelper.http(
            `api/Main/GetGridData?companyId=${activeCompanyId}&path=` +
            newPath +
            '&parentIsApp=' +
            parentIsApp,
            ErrorHelper.GridDataGetError,
        );
      } else {
        return GlobalHelper.http(
            `api?Main/GetGridData?companyId=${activeCompanyId}`,
            ErrorHelper.GridDataGetError,
        );
      }
    },
    async updateGrid(): Promise<void> {
      this.folderImage = window.baseUrl + 'Content/images/folder.svg';
    },
    setPolling(refresh: boolean): void {
      if (refresh && this.polling == null) {
        this.polling = setInterval(() => this.getQueueData(), 5000);
      } else if (!refresh) {
        clearInterval(this.polling);
        this.polling = null;
      }
    },
    onContextMenuInput(input: boolean, menuItem: any): void {
      if (!input) {
        return;
      }
      if (!this.selected.some((selectedItem: any) => selectedItem.Name === menuItem.Name)) {
        this.selected = [menuItem];
        this.changeSelectedFolders(this.selected);
      }
    },
    rename(): void {
      EventBus.$emit('rename');
    },
    download(): void {
      EventBus.$emit('download');
    },
    deleteObjects(): void {
      EventBus.$emit('delete');
    },
    rowClass(item: any): string {
      return item.Name === this.highlightedItem ? 'highlighted-item' : '';
    },
    scrollToHighlightedItem(): void {
      const highlightedItem = this.items.find((x: any) => x.Name === this.highlightedItem);
      this.currentPage = Math.ceil((this.items.indexOf(highlightedItem) + 1) / this.itemsPerPage);
      this.$nextTick(() => {
        const $el = $('.highlighted-item') as any;
        const top = $el.position().top - $el.outerHeight();
        $('.v-data-table__wrapper').animate({ scrollTop: top }, 500, () => {
          $el.css('background', 'inherit');
          setTimeout(() => {
            this.setHighlightedItem(null);
            $el.css('background', '');
          }, 5000);
        });
      });
    },
  },
});
