
import Vue from 'vue';

import { mapActions, mapMutations, mapState } from 'vuex';

import IdleDialog from 'common-vue-components/components/Dialogs/IdleDialog.vue';
import Snackbar from 'common-vue-components/components/Messaging/Snackbar.vue';
import IdleManager from 'common-vue-components/helpers/IdleManager';

import ActivityPage from '@/components/Activity/ActivityPage.vue';
import FolderPath from '@/components/Breadcrumbs/FolderPath.vue';
import LoadControls from '@/components/Controls/LoadControls.vue';
import ConfigurationDialog from '@/components/Dialogs/ConfigurationDialog.vue';
import ContactDialog from '@/components/Dialogs/ContactDialog.vue';
import InformationDialog from '@/components/Dialogs/InformationDialog.vue';
import RepoGrid from '@/components/Grids/RepoGrid.vue';
import NavBar from '@/components/Navs/NavBar.vue';
import NavDrawer from '@/components/Navs/NavDrawer.vue';
import CreateFolder from '@/components/Storage/CreateFolder/CreateFolder.vue';
import Delete from '@/components/Storage/Delete/Delete.vue';
import Download from '@/components/Storage/Download/Download.vue';
import Rename from '@/components/Storage/Rename/Rename.vue';
import Upload from '@/components/Storage/Upload/Upload.vue';
import { Events } from '@/constants';
import { EventBus } from '@/helpers/eventBus';
import { AlertMessage } from '@/models/alertMessage';

export default Vue.extend({
  components: {
    ContactDialog,
    InformationDialog,
    NavBar,
    NavDrawer,
    FolderPath,
    RepoGrid,
    ConfigurationDialog,
    ActivityPage,
    LoadControls,
    IdleDialog,
    CreateFolder,
    Upload,
    Download,
    Delete,
    Rename,
  },
  data(): {
    repoSearch: string;
    snackbarIndex: number;
    totalSnackHeights: number;
    activityDialog: boolean;
    pendingCount: number;
    initialized: boolean;
  } {
    return {
      repoSearch: '',
      snackbarIndex: 0,
      totalSnackHeights: 0,
      activityDialog: false,
      pendingCount: 0,
      initialized: false,
    };
  },
  computed: {
    ...mapState(['hasAccess', 'activeCompany', 'currentLocation', 'queueData', 'failedOperations']),
  },
  watch: {
    authState: {
      handler() {
        if (!this.initialized && this.authState?.isAuthenticated) {
          this.getCompaniesForUser();
          this.initialized = true;
        }
      },
      deep: true,
    },
    currentLocation(): void {
      this.repoSearch = '';
    },
    activityDialog(): void {
      this.changeActivityAutoRefresh(this.activityDialog);
      this.clearFailedNotification();
    },
    queueData(): void {
      this.pendingCount = this.queueData?.length;
    },
  },
  async mounted(): Promise<void> {
    EventBus.$on(
      Events.addSnackbar,
      (alertMessage: AlertMessage, showContactSupport: boolean): void => {
        this.addSnackbarError(alertMessage, showContactSupport);
      },
    );

    EventBus.$on('SnackCreated', (height: number) => {
      this.addSnackbarHeight(height);
    });

    EventBus.$on('SnackDestroyed', (index: number, height: number) => {
      this.subtractSnackbarHeight(height);
    });

    EventBus.$on('openActivity', () => {
      this.activityDialog = true;
    });

    IdleManager.onIdle((idle) => {
      this.changeActivityAutoRefresh(!idle);
    });
  },
  methods: {
    ...mapMutations(['changeActivityAutoRefresh', 'changeFailedOperations']),
    ...mapActions(['getCompaniesForUser']),
    isLoggedIn(): boolean {
      return (
        !!this.authState &&
        !!this.authState.isAuthenticated &&
        !this.$router.currentRoute.path.includes('/login')
      );
    },
    isCompanySelected(): boolean {
      return !!this.activeCompany;
    },
    addSnackbarError(alertMessage: AlertMessage, showContactSupport: boolean): void {
      this.snackbarIndex += 1;
      // eslint-disable-next-line prefer-const
      let component = Vue.extend(Snackbar);
      // eslint-disable-next-line new-cap
      const instance = new component({
        propsData: {
          text: alertMessage.Message,
          index: this.snackbarIndex,
          timeout: alertMessage.DismissTime,
          topValue: this.totalSnackHeights,
          colorKey: alertMessage.Color,
          showContactSupport: showContactSupport,
          errorMessage: alertMessage.ErrorMessage,
          logId: alertMessage.LogId,
          eventBus: EventBus,
        },
      });

      instance.$mount();
      (this.$refs.container as HTMLDivElement).prepend(instance.$el);
    },
    addSnackbarHeight(height: number): void {
      this.totalSnackHeights += height;
    },
    subtractSnackbarHeight(height: number): void {
      this.totalSnackHeights -= height;
    },
    clearFailedNotification(): void {
      if (this.failedOperations) {
        this.changeFailedOperations();
      }
    },
  },
});
