export class QueueItem {
  constructor(
    public Id: number,
    public UserId: string,
    public Type: string,
    public QueueType: number,
    public Path: string,
    public QueueId: string,
    public QueueStatus: string,
    public IsUser: boolean,
    public LogId: number,
    public Succeeded: boolean,
  ) {}
}
