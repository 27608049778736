
import Vue from 'vue';

import { mapMutations, mapState } from 'vuex';

// eslint-disable-next-line no-unused-vars
import { BreadcrumbItem } from '@/models/breadcrumbs';

export default Vue.extend({
  data(): {
    paths: BreadcrumbItem[];
  } {
    return {
      paths: [],
    };
  },
  computed: {
    ...mapState(['currentLocation']),
  },
  watch: {
    currentLocation(): void {
      this.paths = this.currentLocation;
    },
  },
  mounted(): void {
    this.setPath();
    this.paths = this.currentLocation;

    window.addEventListener('popstate', () => {
      this.setPath();
    });
  },
  methods: {
    ...mapMutations(['addNewLocation', 'changeLocationPlacement']),
    setPath() {
      this.changeLocationPlacement();
      if (location.href.indexOf('#') > 0) {
        const url = decodeURIComponent(location.href.substr(location.href.indexOf('#') + 1));
        for (const folder of url.split('/')) {
          folder && this.addNewLocation({ Name: folder, IsFolder: true });
        }
      }
    },
  },
});
