import config from '@/config';
import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';
import { ConfigurationModel } from '@/models';

class ConfigurationService {
  public async getConfiguration(companyId: number): Promise<ConfigurationModel> {
    const response = await GlobalHelper.http(
      `${config.resourceServer}/api/Configuration/GetConfiguration?companyId=${companyId}`,
      ErrorHelper.ConfigurationGetError,
    );
    return response;
  }

  public async getConfigurationPermission(companyId: number): Promise<boolean> {
    const permission = await GlobalHelper.http(
      `${config.resourceServer}/api/Configuration/CanAccessConfiguration?companyId=${companyId}`,
      ErrorHelper.ConfigurationGetError,
    );
    return permission;
  }
}

export const configurationService = new ConfigurationService();
export default configurationService;
