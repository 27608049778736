
import Vue from 'vue';

import $ from 'jquery';
import { mapGetters, mapMutations, mapState } from 'vuex';

import { UploadEventArgs } from 'common-vue-components/components/Dialogs/Upload/types/uploadEventArgs';
import { UploadFileEventArgs } from 'common-vue-components/components/Dialogs/Upload/types/uploadFileEventArgs';
import { UploadFileStatus } from 'common-vue-components/components/Dialogs/Upload/types/uploadFileStatus';
import {
  UploadParamsErrorFileInfo,
  UploadParamsSuccessFileInfo,
} from 'common-vue-components/components/Dialogs/Upload/types/uploadParams';
import { UploadStatus } from 'common-vue-components/components/Dialogs/Upload/types/uploadStatus';
import UploadDialog, {
  UploadDialogRef,
} from 'common-vue-components/components/Dialogs/Upload/UploadDialog.vue';

import { CompleteFileUploadRequest } from '@/components/Storage/Upload/types/completeFileUploadRequest';
import { StartFilesUploadRequest } from '@/components/Storage/Upload/types/startFilesUploadRequest';
import { StartFileUploadResponse } from '@/components/Storage/Upload/types/startFileUploadResponse';
import { ResponseTypes } from '@/helpers/enums';
import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';

const GENERIC_UPLOAD_ERROR =
  'The file(s) upload failed, please try again or contact system administrator for more details';

export default Vue.extend({
  components: { UploadDialog },
  data(): {
    open: boolean;
    refreshOnClose: boolean;
    uploadingFilesCount: number;
    uploadedSuccessfullyFilesCount: number;
    uploadedFailedFilesCount: number;
  } {
    return {
      open: false,
      refreshOnClose: false,
      uploadingFilesCount: 0,
      uploadedSuccessfullyFilesCount: 0,
      uploadedFailedFilesCount: 0,
    };
  },
  computed: {
    ...mapGetters(['currentLocationPath']),
    ...mapState(['currentLocation', 'activeCompany']),
    uploadDialog(): UploadDialogRef {
      return this.$refs.uploadDialog as any as UploadDialogRef;
    },
  },
  methods: {
    ...mapMutations(['refresh']),
    handleUploadClick() {
      this.open = true;
    },
    async handleUpload(e: UploadEventArgs) {
      const request: StartFilesUploadRequest = {
        companyId: this.activeCompany.Id,
        files: e.files.map((file) => ({
          path: file.path,
          size: file.size,
        })),
        currentLocationPath: this.currentLocationPath,
      };

      let startFileUploadResponses: StartFileUploadResponse[] = [];
      try {
        startFileUploadResponses = await $.ajax({
          url: 'api/Storage/StartFilesUpload',
          method: 'POST',
          headers: GlobalHelper.getHeaders(),
          data: request,
        });
      } catch (error) {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage(
          GENERIC_UPLOAD_ERROR,
          ResponseTypes.Error,
          error.responseText,
          true,
        );
        return;
      }

      this.uploadedFailedFilesCount = 0;
      this.uploadedSuccessfullyFilesCount = 0;
      this.uploadingFilesCount = startFileUploadResponses.filter(
        (startFileUploadResponse) => startFileUploadResponse.ResponseType === ResponseTypes.Success,
      ).length;
      if (!this.uploadingFilesCount) {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage(GENERIC_UPLOAD_ERROR, ResponseTypes.Error, '', true);
        return;
      }

      try {
        await this.uploadDialog.upload({
          files: startFileUploadResponses.map((startFileUploadResponse) => {
            const fileName = startFileUploadResponse.FileName;
            const filePath = startFileUploadResponse.FilePath;
            switch (startFileUploadResponse.ResponseType) {
              case ResponseTypes.Error: {
                const errorFileInfo: UploadParamsErrorFileInfo = {
                  filePath,
                  fileName,
                  status: UploadFileStatus.Error,
                  message: startFileUploadResponse.ErrorMessage,
                };
                return errorFileInfo;
              }
              case ResponseTypes.Success: {
                const successFileInfo: UploadParamsSuccessFileInfo = {
                  filePath,
                  fileName,
                  status: UploadFileStatus.Success,
                  uploadId: startFileUploadResponse.UploadId,
                  partSize: startFileUploadResponse.PartSize,
                  partUrls: startFileUploadResponse.PartUrls,
                };
                return successFileInfo;
              }
            }
          }),
        });
      } catch (error) {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage(GENERIC_UPLOAD_ERROR, ResponseTypes.Error, '', true);
      }
    },
    async handleUploadFile(e: UploadFileEventArgs) {
      const request: CompleteFileUploadRequest = {
        companyId: this.activeCompany.Id,
        uploadId: e.uploadId,
        path: e.path,
        partETags: e.eTags,
      };

      try {
        await $.ajax({
          url: 'api/Storage/CompleteFileUpload',
          method: 'POST',
          headers: GlobalHelper.getHeaders(),
          data: request,
        });
        this.refreshOnClose = true;
        this.uploadedSuccessfullyFilesCount++;
        this.uploadDialog.setFileUploadSuccess(e.path);
      } catch (error) {
        this.uploadedFailedFilesCount++;
        this.uploadDialog.setFileUploadError(e.path);
      }

      this.checkUploadFinished();
    },
    async handleUploadFileFail() {
      this.uploadedFailedFilesCount++;
      this.checkUploadFinished();
    },
    handleUploadDialogClose() {
      this.open = false;

      if (this.refreshOnClose) {
        this.refreshOnClose = false;
        this.refresh();
      }
    },
    checkUploadFinished() {
      if (
        this.uploadingFilesCount ===
        this.uploadedFailedFilesCount + this.uploadedSuccessfullyFilesCount
      ) {
        this.uploadDialog.setUploadStatus(UploadStatus.Success);
        if (this.uploadedFailedFilesCount) {
          ErrorHelper.addSnackbarMessage(
            `${this.uploadedSuccessfullyFilesCount} files have successfully uploaded, and ${this.uploadedFailedFilesCount} files have failed upload, please try again or contact system administrator for more details.`,
            'warning',
            '',
            true,
          );
        } else {
          ErrorHelper.addSnackbarMessage(
            'The file(s) uploaded successfully',
            ResponseTypes.Success,
          );
        }
      }
    },
  },
});
