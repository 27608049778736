import Vue from 'vue';

import init from 'common-vue-components/init';

import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

declare global {
  interface Window {
    version: string;
    baseUrl: string;
    companyName: string;
  }
}

init();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
