export default class LoadDataGridBuilder {
  public static DataGridShiftIgnore(item: any, selected: any[]) {
    const itemObj = item.item as any;
    const includes = selected.includes(itemObj);
    if(item.value === true && !includes)
    {
      selected.push(itemObj);
    } else if(item.value === false && includes)
    {
      selected = selected.filter(t => t !== itemObj);
    }
    return selected;
  }
}
