
import Vue from 'vue';

import $ from 'jquery';
import { InputValidationRules } from 'vuetify';
import { mapGetters, mapMutations, mapState } from 'vuex';

import CreateFolderResponse from '@/components/Storage/CreateFolder/types/createFolderResponse';
import { ResponseTypes } from '@/helpers/enums';
import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';

export default Vue.extend({
  data(): {
    dialog: boolean;
    folderName: string;
    nameError: string;
    warningText: string;
    submitting: boolean;
  } {
    return {
      dialog: false,
      folderName: '',
      nameError: '',
      warningText: '',
      submitting: false,
    };
  },
  computed: {
    ...mapState(['activeCompany']),
    ...mapGetters(['currentLocationPath']),
    nameRules(): InputValidationRules {
      return this.nameError ? [this.nameError] : [];
    },
  },
  watch: {
    folderName: function () {
      if (this.folderName.indexOf('.') !== -1) {
        this.warningText = "It's recommended not to use period in the folder name";
      } else {
        this.warningText = '';
      }

      this.nameError = '';
    },
  },
  methods: {
    ...mapMutations(['refresh', 'setHighlightedItem']),
    openDialog() {
      this.dialog = true;
      this.folderName = '';
      this.nameError = '';
    },
    closeDialog() {
      this.dialog = false;
    },
    async createFolder() {
      if (!this.activeCompany) {
        return;
      }

      this.submitting = true;
      this.nameError = '';

      await $.ajax({
        url: 'api/Storage/CreateFolder',
        method: 'POST',
        headers: GlobalHelper.getHeaders(),
        data: {
          companyId: this.activeCompany.Id,
          newFolderName: this.folderName,
          currentLocationPath: this.currentLocationPath,
        },
      })
        .done((response: CreateFolderResponse) => {
          if (!response.IsSuccess) {
            this.nameError = response.ErrorMessage;
          } else {
            ErrorHelper.addSnackbarMessage('Folder created successfully', ResponseTypes.Success);
            this.setHighlightedItem(this.folderName);
          }
        })
        .fail((error: any) => {
          ErrorHelper.addSnackbarMessage(
            'Folder creation failed',
            ResponseTypes.Error,
            error.responseText,
            true,
          );
          this.refresh();
        })
        .always(() => {
          this.submitting = false;
          if (!this.nameError) {
            this.closeDialog();
            this.refresh();
          }
        });
    },
  },
});
