
import Vue from 'vue';

import { mapMutations, mapState } from 'vuex';

import NineDot from 'common-vue-components/components/Dropdowns/NineDot.vue';

import Info from '@/components/Dropdowns/Info.vue';

export default Vue.extend({
  components: {
    Info,
    NineDot,
  },
  data(): {
    drawer: boolean;
  } {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState(['drawerState', 'activeCompany', 'canAccessConfiguration']),
  },
  watch: {
    drawerState(): void {
      this.drawer = this.drawerState;
    },
    drawer(): void {
      if (this.drawer === false) {
        this.changeDrawerState();
      }
    },
  },
  methods: {
    ...mapMutations(['changeDrawerState', 'changeConfigurationDialogState']),
    signOut(): void {
      this.$auth.signOut();
    },
    openConfiguration(): void {
      this.changeConfigurationDialogState();
    },
  },
});
